import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
}

const BlockContent = require("@sanity/block-content-to-react")

export const data = graphql`
  {
    sanitySiteSettings {
      _rawRulesAndRegulations
      _rawPrivacyPolicy
    }
  }
`

export default function ({ data }) {
  const rulesAndRegulations = data.sanitySiteSettings._rawRulesAndRegulations
  return (
    <Layout>
      <SEO title="Rules"/>
        <section className="sanity-block my-8 mb-16 px-4 sm:px-8">
          <BlockContent
            blocks={rulesAndRegulations}
            serializers={serializers}
          />
        </section>
      
    </Layout>
  )
}
